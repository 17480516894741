import React from 'react';
import styled from 'styled-components';

const ToggleBtn = ({ isToggled, onToggled }) => {
	return (
		<Label className="relative inline-block w-12 h-3 mx-auto">
			<input
				type="checkbox"
				className="opacity-0 w-0 h-0"
				checked={isToggled}
				onChange={onToggled}
			/>
			<span
				className="absolute cursor-pointer top-0 left-0 right-0 bottom-0 rounded-3xl bg-[#c83434] 
            before:content-'' before:absolute before:h-[18px] before:w-[18px] before:rounded-full before:left-1 before:bottom-[-3px] before:bg-neutral-300
            before:transition-all"
			></span>
		</Label>
	);
};

const Label = styled.label`
	input:checked + span:before {
		transform: translateX(22px);
		transition: 0.5 ease;
	}
	input:checked + span {
		background-color: #16a34a;
	}
`;

export default ToggleBtn;
