import React, { useEffect, useState } from 'react';
import { styled } from 'styled-components';
import MovieCard from '../components/client/MovieCard';

const Movies = () => {
	const [allMovies, setAllMovies] = useState('');

	useEffect(() => {
		const fetchAllMovies = async () => {
			const response = await fetch(
				`${process.env.REACT_APP_SERVER}/admin/movies`
			);
			const json = await response.json();
			if (response.ok) {
				setAllMovies(json);
			}
		};

		fetchAllMovies();
	}, []);
	// console.log(allMovies);
	return (
		<Container>
			<MovieCard movies={allMovies} />
		</Container>
	);
};

const Container = styled.section`
	color: #fff;
	background-color: #232020;
	height: fit-content;
	width: 100%;
	position: relative;
	margin-top: 4rem;

	.card-container {
		margin: 2rem auto;
		width: 90%;
		background-color: pink;
	}
`;

export default Movies;
