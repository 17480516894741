import React, { useState } from 'react';
import { useLocation } from 'react-router-dom';
import ToggleBtn from '../components/ToggleBtn';

function BookingSummary() {
	const location = useLocation();
	const booking_summary = location.state;
	const [addSnacks, setAddSnacks] = useState(false);

	const handleChange = (e) => {
		const newAddSnacks = !addSnacks;
		setAddSnacks(newAddSnacks);
		booking_summary['snacks'] = newAddSnacks;

		if (newAddSnacks === true) {
			booking_summary['snacks_price'] = 1500;
			booking_summary['total_price'] =
				booking_summary.ticket_price + 1500 * booking_summary.noPersons;
		} else {
			booking_summary['snacks_price'] = 0;
			booking_summary['total_price'] = booking_summary.ticket_price;
		}
		// console.log(booking_summary);
	};

	const handleBooking = async () => {
		try {
			const response = await fetch(process.env.REACT_APP_SERVER + '/booking', {
				method: 'POST',
				headers: { 'Content-Type': 'application/json' },
				body: JSON.stringify(booking_summary),
			});
			if (response.ok) {
				alert(`${booking_summary.title} booking successful`);
				// fetch(process.env.REACT_APP_SERVER + '/success/')
				// 	.then((res) => res.text())
				// 	.then((data) => {
				// 		console.log(data);
				// 	});
				// console.log(booking_summary);
			}
		} catch (error) {
			console.log(error);
			alert(`Error booking ${booking_summary.title}. Please try Again`);
		}
	};

	return (
		<div className="w-[45%] h-[650px] bg-zinc-800 relative top-[5%] left-[15%] rounded-b-[20%]">
			{/* Summary Container */}
			<div className="w-full h-full relative">
				<p className="text-4xl border-b-2 border-neutral-400 text-center p-3 text-neutral-400">
					Booking Summary
				</p>

				{/* Content summary */}
				<div className="w-[90%] mx-auto pt-8 font-mono">
					<ul className="flex flex-col justify-around">
						<li className="flex justify-between border-b-[1px] border-neutral-700 p-3">
							<span className="text-lg font-bold text-neutral-400">Title</span>
							<span className="font-medium text-xl text-neutral-400">
								{booking_summary.title}
							</span>
						</li>
						<li className="flex justify-between border-b-[1px] border-neutral-700 p-3">
							<span className="text-lg font-bold text-neutral-400">Date</span>
							<span className="font-medium text-xl text-neutral-400">
								{`${booking_summary.date}`}
							</span>
						</li>
						<li className="flex justify-between border-b-[1px] border-neutral-700 p-3">
							<span className="text-lg font-bold text-neutral-400">
								No. of person(s)
							</span>
							<span className="font-medium text-xl text-neutral-400">
								{`${booking_summary.noPersons}`}
							</span>
						</li>
						<li className="flex justify-between border-b-[1px] border-neutral-700 p-3">
							<span className="text-lg font-bold text-neutral-400">
								Seat number(s)
							</span>
							<span className="font-medium text-xl text-neutral-400">
								{`${booking_summary.selected_seats}`}
							</span>
						</li>
						<li className="flex justify-between border-b-[1px] border-neutral-700 p-3">
							<span className="text-lg font-bold text-neutral-400">
								Ticket price
							</span>
							<span className="font-medium text-xl text-neutral-400">
								{`₦${booking_summary.ticket_price.toLocaleString()}`}
							</span>
						</li>
						<li className="flex justify-between border-b-[1px] border-neutral-700 p-3">
							<span className="text-lg font-bold text-neutral-400">
								Popcorn & Drink
							</span>
							<span className="font-medium text-xl text-neutral-400 flex flex-col gap-2">
								<ToggleBtn isToggled={addSnacks} onToggled={handleChange} />
								<span className="text-sm text-neutral-500">
									₦1,500 / person
								</span>
							</span>
						</li>
						<li className="flex justify-between border-b-[1px] border-neutral-700 p-3">
							<span className="text-lg font-bold text-neutral-400">
								Total price
							</span>
							<span className="font-medium text-xl text-neutral-400 flex flex-col gap-2">
								{/* {`₦${booking_summary.total_price.toLocaleString()}`} */}
								{addSnacks
									? `₦${(
											booking_summary.ticket_price +
											1500 * booking_summary.noPersons
									  ).toLocaleString()}`
									: `₦${booking_summary.ticket_price.toLocaleString()}`}
							</span>
						</li>
					</ul>
				</div>
				<button
					className="w-[170px] block mx-auto mt-8 bg-[#a62727] text-[#ffffff] text-lg p-4 rounded-[10px]"
					onClick={handleBooking}
				>
					Confirm
				</button>
			</div>
		</div>
	);
}

export default BookingSummary;
