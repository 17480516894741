import React, { useEffect } from 'react';
import { Link } from 'react-router-dom';
import { styled } from 'styled-components';
import { Swiper, SwiperSlide } from 'swiper/react';
import { Navigation } from 'swiper';
import '../../App.css';
import 'swiper/swiper.min.css';
import 'swiper/swiper-bundle.min.css';
import 'swiper/css/navigation';

export const MovieList = ({ movies, title }) => {
	useEffect(() => {}, [movies]);

	return (
		<Container>
			<div className="meta">
				<p>{title}</p>
			</div>
			<Swiper
				modules={[Navigation]}
				navigation
				spaceBetween={20}
				slidesPerView={4}
			>
				{movies?.length ? (
					movies.map((item) => {
						return (
							<SwiperSlide key={item._id}>
								<Link to={'/movie/' + item._id}>
									<img
										src={item.poster}
										alt="movie poster"
										style={{
											height: '350px',
											width: '250px',
										}}
									/>
									<div className="movie-list-overlay">
										<p className="title">{item.title}</p>
										<ul className="stats">
											<li className="date">
												<p>2023</p>
											</li>
											<li className="rating">
												<p>4.4</p>
											</li>
										</ul>
										<button
										//  onClick={() => handleBook(item.title)}
										>
											Book Now{' '}
										</button>
									</div>
								</Link>
							</SwiperSlide>
						);
					})
				) : (
					<h2 className="text-white text-center p-11">Nothing to see here. </h2>
				)}
			</Swiper>
		</Container>
	);
};

const Container = styled.div`
	.meta {
		padding: 1em 0;
		p {
			color: #ddc3c3;
			text-transform: capitalize;
			font-weight: bold;
			font-size: 1.3em;
		}
	}
	.swiper-slide {
		width: fit-content !important;
		/* width: calc(calc(63vw / 4) - 0.6em - (1.5em / 4)); */
		position: relative;
		img {
			width: 100%;
			border-radius: 0.5em;
		}

		.movie-list-overlay {
			position: absolute;
			bottom: 0;
			left: 0;
			width: 100%;
			height: 35%;
			/* background: conic-gradient(from 76.33deg at 50.12% 6.25%, #322121 0deg, rgba(46, 22, 22, 0.253748) 268.65deg, rgba(63, 31, 31, 0) 360deg); */
			/* background: conic-gradient(from 180deg at 50% 50%, rgba(181, 167, 43, 0.199041) -60deg, #ADA02B 48.75deg, rgba(183, 169, 43, 0) 127.69deg, rgba(176, 163, 43, 0.665182) 193.12deg, rgba(181, 167, 43, 0.199041) 300deg, #ADA02B 408.75deg); */
			/* background-color: #ffffff20; */
			backdrop-filter: blur(20px);
			/* Note: backdrop-filter has minimal browser support */
			padding: 1em 1em 0.6em 1em;
			color: #fff;
			display: flex;
			flex-direction: column;
			justify-content: space-between;
			p.title {
				font-weight: bold;
				font-size: 1.1em;
				white-space: nowrap;
				height: 1.2em;
				text-overflow: ellipsis;
				overflow: hidden;
				text-transform: capitalize;
				text-overflow: ellipsis;
			}
			.stats {
				display: flex;
				flex-direction: row;
				justify-content: space-between;
				font-size: 0.8em;
				padding-top: 0.3em;
			}
			button {
				margin-top: 0.3em;
				height: 2.5em;
				background-color: #a62727;
				outline: 0;
				border: 0;
				color: #fff;
				font-weight: bold;
				border-radius: 0.2em;
			}
		}
	}
`;
