import React, { useEffect, useState } from 'react';
import { styled } from 'styled-components';
import MovieCard from '../components/client/MovieCard';

const Discover = () => {
	const [allMovies, setAllMovies] = useState('');

	useEffect(() => {
		const fetchAllMovies = async () => {
			const response = await fetch(`${process.env.REACT_APP_SERVER}/admin/all`);
			const json = await response.json();
			if (response.ok) {
				setAllMovies(json);
			}
		};

		fetchAllMovies();
	}, []);
	// console.log(allMovies);
	return (
		<Container>
			<MovieCard movies={allMovies} />
		</Container>
	);
};

const Container = styled.section`
	color: #fff;
	/* background-color: #232020; */
	height: fit-content;
	width: 100%;
	position: relative;
	padding-top: 3%;

	.card-container {
		margin: 2rem auto;
		width: 90%;
		background-color: pink;
	}
`;

export default Discover;
