import React, { useState, useEffect } from 'react';
import { useNavigate, useLocation } from 'react-router-dom';
import SeatGrid from '../SeatGrid';

function SeatsBooking() {
	const [selectedSeats, setSelectedSeats] = useState([]);

	const location = useLocation();
	const navigate = useNavigate();
	const booking_summary = location.state;

	useEffect(() => {
		booking_summary['selected_seats'] = selectedSeats;
	}, [selectedSeats, booking_summary]);

	const handleSeatSelection = (isSelected, seatId) => {
		if (isSelected) {
			if (selectedSeats.length < booking_summary.noPersons) {
				setSelectedSeats([...selectedSeats, seatId]);
			} else {
				alert(
					`You have ${booking_summary.noPersons} person(s) & can only select ${booking_summary.noPersons} seats`
				);
			}
		} else {
			setSelectedSeats(
				selectedSeats.filter((seat) => {
					return seat !== seatId;
				})
			);
			
		}
	};

	const confirmSeats = () => {
		navigate(`/booking-summary/${booking_summary.movieID}`, {
			state: booking_summary,
		});
	};

	return (
		<div
			className={`w-full h-full relative top-0 left-0 bottom-0 right-0 flex flex-col justify-center`}
		>
			<div className="screen w-[40%] h-[300px] bg-neutral-500 mx-auto my-3 flex justify-center items-center">
				<span className="text-3xl">Screen</span>
			</div>
			<div className="w-[60%] h-[80%] flex flex-col items-center gap-10  mx-auto p-8">
				<SeatGrid
					numRows={8}
					numCols={5}
					onSeatSelection={handleSeatSelection}
				/>
				<button
					onClick={confirmSeats}
					className="w-[200px] bg-[#a62727] text-white p-5 rounded-md text-lg"
				>
					Confirm seat
				</button>
			</div>
		</div>
	);
}

export default SeatsBooking;
