import React from 'react';
import Seats from './Seats';

function SeatGrid({ numRows, numCols, onSeatSelection,maxSelectionReached }) {
	const renderSeats = () => {
		const seats = [];
        const rowId = ['A', 'B', 'C', 'D', 'E', 'F','G','H'];
		for (let row = 0; row < numRows; row++) {
			const rowSeats = [];
			const myrow = rowId[row]
			for (let col = 0; col < numCols; col++) {
				const seatId = `${myrow}${col + 1}`;
				rowSeats.push(
					<Seats
						key={seatId}
						seatId={seatId}
						onSeatSelection={onSeatSelection}
                        maxSelectionReached={maxSelectionReached}
						
					/>
				);
			}
			seats.push(
				<div
					key={`row-${row}`}
					className="seat-row flex justify-center items-center gap-3"
				>
					{rowSeats}
				</div>
			);
		}
		return seats;
	};

	return <div className="flex flex-col gap-3">{renderSeats()}</div>;
}

export default SeatGrid;
