import styled from 'styled-components';
import { Movies } from '../components/client/Movies';
import { MovieNav } from '../components/client/MovieNav';
import { UserContext } from '../hooks/UserContext';
import { useContext } from 'react';
import AdminHome from '../components/admin/AdminHome';

export const Home = () => {
	const { isAdmin } = useContext(UserContext);
	return (
		<Container>
			{!isAdmin ? (
				<>
					<Movies />
					<MovieNav />
				</>
			) : (
				<>
					<AdminHome />
				</>
			)}
		</Container>
	);
};

const Container = styled.div`
	display: flex;
	flex-direction: row;
	height: max-content;
`;
