import React, { useState } from 'react';
import { Navigate } from 'react-router-dom';
import styled from 'styled-components';

const Signup = () => {
	const [username, setUsername] = useState('');
	const [name, setName] = useState('');
	const [email, setEmail] = useState('');
	const [password, setPassword] = useState('');
	const [redirect, setRedirect] = useState(false);

	async function signUp(e) {
		e.preventDefault();
		const response = await fetch(`${process.env.REACT_APP_SERVER}/signup`, {
			method: 'POST',
			body: JSON.stringify({ name, username, email, password }),
			headers: { 'Content-type': 'application/json' },
		});

		if (response.status === 200) {
			setName(' ');
			setUsername(' ');
			setEmail(' ');
			setPassword(' ');
			alert('Registration Successful');
			setRedirect(true);
		} else {
			alert('Registration Failed');
		}
	}

	if (redirect) {
		return <Navigate to={'/'} />;
	}
	return (
		<Container>
			<form onSubmit={signUp}>
				<h2>Sign up</h2>
				<label htmlFor="username">
					<h3>Name</h3>
					<input
						type="text"
						name="name"
						placeholder="e.g John Doe"
						value={name}
						onChange={(e) => setName(e.target.value)}
					/>
				</label>
				<label htmlFor="username">
					<h3>Username</h3>
					<input
						type="text"
						name="username"
						placeholder="Username"
						value={username}
						onChange={(e) => setUsername(e.target.value)}
					/>
				</label>
				<label htmlFor="email">
					<h3>Email</h3>
					<input
						type="email"
						name="email"
						placeholder="elon@mail.com"
						value={email}
						onChange={(e) => setEmail(e.target.value)}
					/>
				</label>
				<label htmlFor="password">
					<h3>Password</h3>
					<input
						type="password"
						name="password"
						placeholder="Password"
						value={password}
						onChange={(e) => setPassword(e.target.value)}
					/>
				</label>

				<button>Sign up</button>
			</form>
		</Container>
	);
};
const Container = styled.section`
	width: 100%;
	background-color: #232020;
	display: flex;
	justify-content: center;
	align-items: center;

	h2 {
		color: #eee;
	}

	form {
		border: 1px solid #eee;
		border-radius: 10px;
		background-color: #1a1717;
		width: 50%;
		display: flex;
		justify-content: space-around;
		align-items: center;
		gap: 1rem;
		flex-direction: column;
		height: fit-content;
		position: relative;
		padding: 1.5rem 0;

		label {
			width: 60%;
			position: relative;
			color: #eee;
		}

		input {
			height: 50px;
			width: 100%;
			padding: 1rem;
			border-radius: 10px;
			/* outline: none; */
			/* border: none; */
			background-color: #ddd;
		}
		button {
			height: 3rem;
			width: 10rem;
			font-size: 1.3rem;
			background-color: #a62727;
			outline: 0;
			border: 0;
			color: #fff;
			font-weight: bold;
			border-radius: 0.2em;
			box-shadow: 0px 0px 5px 5px rgba(0, 0, 0, 0.1);
		}
	}
`;
export default Signup;
