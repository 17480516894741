import styled from 'styled-components';
import { MovieNavSearch } from './MovieNavSearch';
import { MovieNavList } from './MovieNavList';

export const MovieNav = () => {
	const popularMovies = [
		{
			title: 'breaking bad',
			tags: ['drama', 'crime'],
			image: '/images/breakingbad.jpeg',
		},
		{
			title: 'better call saul',
			tags: ['drama'],
			image: '/images/bcs.jpeg',
		},
	];
	return (
		<Container>
			<MovieNavSearch />
			<MovieNavList movies={popularMovies} title="popular movies" />
		</Container>
	);
};

const Container = styled.div`
	width: 22vw;
	background-color: #1a1717;
	padding: 2em;
`;
