import React, { useState } from 'react';
import styled from 'styled-components';
import { Navigate } from 'react-router-dom';

export const MovieNavList = ({ title, movies }) => {
	const [navigate, setNavigate] = useState(false);

	const handleNavigate = () => {
		setNavigate(!navigate);
	};

	if (navigate) return <Navigate to={'/discover'} replace />;
	return (
		<Container>
			<p>{title}</p>
			<ul>
				{movies.map((movie, key) => (
					<li key={key}>
						<img src={movie.image} alt="movie" />
						<div className="details">
							<p className="title">{movie.title}</p>
							<p className="date">2008 - 2013</p>
							<ul className="tags">
								{movie.tags.map((tag, key) => (
									<li key={key}>
										<p>{tag}</p>
									</li>
								))}
							</ul>
						</div>
					</li>
				))}
			</ul>
			<button onClick={handleNavigate}>see more</button>
		</Container>
	);
};

const Container = styled.div`
	padding: 1em 0;
	> p {
		color: #fff;
		text-transform: capitalize;
		font-weight: bold;
		font-size: 1.2em;
		margin: 1em 0;
	}
	> ul {
		display: flex;
		flex-direction: column;
		width: 100%;
		gap: 1em;
		> li {
			width: 100%;
			height: 5.8em;
			padding: 0.4em;
			border-radius: 0.5em;
			background-color: #2f2b2b;
			display: flex;
			flex-direction: row;
			img {
				height: 5em;
				width: 5em;
				min-width: 5em;
				object-fit: cover;
				border-radius: 0.5em;
			}
			.details {
				display: flex;
				flex-direction: column;
				padding: 0 1em;
				color: white;
				justify-content: space-around;
				.title {
					font-weight: bold;
					text-transform: capitalize;
				}
				.date {
					font-size: 0.7em;
					color: #ccc;
				}
				ul {
					display: flex;
					flex-direction: row;
					gap: 0.4em;
					li {
						border: 1px solid #646464;
						border-radius: 0.3em;
						p {
							color: #ccc;
							font-size: 0.8em;
							padding: 0.3em 0.5em;
							text-transform: capitalize;
						}
					}
				}
			}
		}
	}
	> button {
		background-color: transparent;
		color: #fff;
		font-size: 0.9em;
		text-transform: capitalize;
		border: 0;
		outline: 0;
		width: 100%;
		margin-top: 1em;
		padding: 0.7em;
		background-color: #a62727;
		border-radius: 0.4em;
	}
`;
