import React, { useEffect, useState } from 'react';

const Bookings = () => {
	const [items, setItems] = useState('');
	useEffect(() => {
		const fetchBookings = async () => {
			const response = await fetch(
				`${process.env.REACT_APP_SERVER}/booking/bookings`
			);
			const json = await response.json();
			// console.log(json);

			if (response.ok) {
				setItems(json);
			}
		};

		fetchBookings();
	}, []);
	console.log(items);

	return (
		<div className="w-[85vw]">
			<div className="content w-[90%] h-[50%] mx-auto my-[4rem]">
				<h2 className="text-neutral-200 text-4xl mb-3">Bookings</h2>

				<section className="movie-items-container h-[400px] flex flex-col gap-4">
					{items?.length ? (
						items.map((item) => {
							return (
								<div className="item-div flex bg-neutral-800 gap-[5rem] relative">
									<img
										src={item.poster}
										alt={`${item.title} movie poster`}
										style={{
											width: '250px',
											height: '250px',
											objectFit: 'cover',
										}}
									/>

									<table className="w-[50%]">
										<caption className="text-1xl text-gray-500">
											Details
										</caption>
										<tbody>
											<tr>
												<td className="font-mono text-[#9ca3af]">Title</td>
												<td className="font-mono text-[#d1d5db]">
													{item.title}
												</td>
											</tr>
											<tr>
												<td className="font-mono text-[#9ca3af]">
													No. of person(s)
												</td>
												<td className="font-mono text-[#d1d5db]">
													{item.noPersons}
												</td>
											</tr>
											<tr>
												<td className="font-mono text-[#9ca3af]">
													Seat number(s)
												</td>
												<td className="font-mono text-[#d1d5db]">
													{item.selected_seats.join(', ')}
												</td>
											</tr>
											<tr>
												<td className="font-mono text-[#9ca3af]">Date</td>
												<td className="font-mono text-[#d1d5db]">
													{item.date}
												</td>
											</tr>
											<tr>
												<td className="font-mono text-[#9ca3af]">
													Ticket Price
												</td>
												<td className="font-mono text-[#d1d5db]">
													₦{item.ticket_price.toLocaleString()}
												</td>
											</tr>
											<tr>
												<td className="font-mono text-[#9ca3af]">Snacks</td>
												{item.snacks ? (
													<td className="font-mono text-[#d1d5db]">Yes</td>
												) : (
													<td className="font-mono text-[#d1d5db]">No</td>
												)}
											</tr>
											<tr>
												<td className="font-mono text-[#9ca3af]">
													Total Price
												</td>
												<td className="font-mono text-[#d1d5db]">
													₦{item.total_price.toLocaleString()}
												</td>
											</tr>
										</tbody>
									</table>
								</div>
							);
						})
					) : (
						<h1>NO ITEMS HERE</h1>
					)}
				</section>
			</div>
		</div>
	);
};

export default Bookings;
