import { useState, useEffect } from 'react';
import styled from 'styled-components';
import { Slider } from './Slider';
import { Link } from 'react-router-dom';
import { MovieList } from './MovieList';

export const Movies = () => {
	const [myMovies, setMyMovies] = useState();
	const [mySeries, setMySeries] = useState();
	const [myAnime, setMyAnime] = useState();

	useEffect(() => {
		const setstate = (type, json) => {
			switch (type) {
				case 'movies':
					setMyMovies(json);
					break;
				case 'series':
					setMySeries(json);
					break;
				case 'animes':
					setMyAnime(json);
					break;
				default:
					console.log(json);
			}
		};
		const getMovies = async (type) => {
			await fetch(`${process.env.REACT_APP_SERVER}/admin/eight-${type}`)
				.then((res) => res.json())
				.then((res) => {
					setstate(type, res);
				})
				.catch((err) => {});
		};

		getMovies('movies');
		getMovies('series');
		getMovies('animes');
	}, []);
	useEffect(() => {}, [myMovies]);

	const movies = [
		{
			title: 'the little Mermaid',
			tags: ['adventure', 'family', 'fantasy', 'romance', 'musical'],
			image: '/images/little-mermaid.jpg',
		},
		{
			title: 'fast x',
			tags: ['action', 'crime', 'thriller'],
			image: '/images/fast-x.jpg',
		},
		{
			title: 'spider-man: across the spider-Verse',
			tags: ['animation', 'action', 'adventure', 'science fiction'],
			image: '/images/spiderman.jpg',
		},
		{
			title: 'guardians of the galaxy volume 3',
			tags: ['science', 'fiction', 'adventure', 'comedy'],
			image: '/images/guardians.jpg',
		},
		{
			title: 'transformers: rise of the beasts',
			tags: ['action', 'advneture', 'science fiction'],
		},
	];

	return (
		<Container>
			<ul className="movie-nav">
				<li>
					<Link>TV Series</Link>
				</li>
				<li>
					<Link>Movies</Link>
				</li>
				<li>
					<Link>Anime</Link>
				</li>
			</ul>
			<Slider movies={movies} />

			<MovieList title="Movies" movies={myMovies} />
			<MovieList title="Tv Series" movies={mySeries} />
			<MovieList title="Anime" movies={myAnime} />
		</Container>
	);
};

const Container = styled.div`
	width: 63vw;
	padding: 0 1.2em;
	background-color: #232020;
	height: max-content;
	.movie-nav {
		display: flex;
		flex-direction: row;
		padding: 1em 0;
		color: #fff;
		gap: 2em;
		font-size: 0.9em;
		li {
			:hover {
				color: #d21b1b;
			}
			a {
				color: inherit;
			}
		}
	}
`;
