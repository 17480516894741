import React, { useState, useContext } from 'react';
import { Navigate } from 'react-router-dom';
import styled from 'styled-components';
import { UserContext } from '../hooks/UserContext';

function Login() {
	const [username, setUsername] = useState('');
	const [password, setPassword] = useState('');
	const [redirect, setRedirect] = useState(false);
	const { setUserName, setId, setEmail, setName, setIsAdmin } =
		useContext(UserContext);

	async function login(e) {
		e.preventDefault();
		await fetch(`${process.env.REACT_APP_SERVER}/login`, {
			method: 'POST',
			body: JSON.stringify({ username, password }),
			headers: { 'Content-Type': 'application/json' },
			credentials: 'include',
		})
			.then((res) => res.json())
			.then((res) => {
				setUserName(res.username);
				setId(res['_id']);
				setName(res.name);
				setEmail(res.email);
				setRedirect(true);
				alert('Successful');
			})
			.catch((err) => {
				alert('Wrong credentials');
			});
	}

	if (redirect && username !== 'Admin') {
		setIsAdmin(false);
		return <Navigate to="/" />;
	} else if (redirect && username === 'Admin') {
		setIsAdmin(true);
		return <Navigate to="/admin" />;
	}

	return (
		<Container>
			<form onSubmit={login}>
				<h2>Login</h2>
				<label htmlFor="username">
					<h3>Username</h3>
					<input
						type="text"
						name="username"
						placeholder="Username"
						value={username}
						onChange={(e) => setUsername(e.target.value)}
					/>
				</label>
				<label htmlFor="password">
					<h3>Password</h3>
					<input
						type="password"
						name="password"
						placeholder="Password"
						value={password}
						onChange={(e) => setPassword(e.target.value)}
					/>
				</label>

				<button type="submit">Login</button>
			</form>
		</Container>
	);
}

const Container = styled.section`
	width: 100%;
	background-color: #232020;
	display: flex;
	justify-content: center;
	align-items: center;

	h2 {
		color: #eee;
	}

	form {
		border: 1px solid #eee;
		border-radius: 10px;
		background-color: #1a1717;
		width: 50%;
		display: flex;
		justify-content: space-around;
		align-items: center;
		flex-direction: column;
		height: 50vh;
		position: relative;
		padding: 1.5rem 0;

		label {
			width: 60%;
			position: relative;
			color: #eee;
		}

		input {
			height: 50px;
			width: 100%;
			padding: 1rem;
			border-radius: 10px;
			color: #121212;
			/* outline: none; */
			/* border: none; */
			background-color: #ddd;
		}
		button {
			height: 3rem;
			width: 10rem;
			font-size: 1.3rem;
			background-color: #a62727;
			outline: 0;
			border: 0;
			color: #fff;
			font-weight: bold;
			border-radius: 0.2em;
			box-shadow: 0px 0px 5px 5px rgba(0, 0, 0, 0.1);
		}
	}
`;

export default Login;
