import { createContext } from 'react';

export const UserContext = createContext({
	isAdmin: true,
	userName: '',
	setUserName: () => {},
	id: '',
	setId: () => {},
	name: '',
	setName: () => {},
	email: '',
	setEmail: () => {},
	reset: () => {},
	setIsAdmin: () => {},
});
