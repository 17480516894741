import React, { useState } from 'react';

function Seats({ seatId, onSeatSelection }) {
	const [isSelected, setIsSelected] = useState(false);

	const toggleSelection = () => {
		setIsSelected(!isSelected);
		onSeatSelection(!isSelected, seatId);
	};

	return (
		<div
			className={`seat ${
				isSelected ? 'selected bg-green-700 text-slate-300' : 'bg-slate-400'
			} relative w-[40px] h-[40px] rounded-lg  cursor-pointer  flex justify-center items-center`}
			onClick={toggleSelection}
		>
			{seatId}
		</div>
	);
}

export default Seats;
