import { useState, useEffect } from 'react';
import { Navigate } from 'react-router-dom';
import styled from 'styled-components';
import { FaCloudUploadAlt } from 'react-icons/fa';
import { FaTrashAlt } from 'react-icons/fa';

function AdminForm() {
	const [title, setTitle] = useState('');
	const [genre, setGenre] = useState('');
	const [cast, setCast] = useState('');
	const [type, setType] = useState('');
	const [synopsis, setSynopsis] = useState('');
	const [poster, setPoster] = useState();
	const [imgLink, setImgLink] = useState();
	const [amount, setAmount] = useState('');
	const [redirect, setRedirect] = useState(false);

	async function addMovie(e) {
		e.preventDefault();
		// const data = new FormData();
		// data.append('title', title);
		// data.append('cast', cast);
		// data.append('genre', genre);
		// data.append('type', type);
		// data.append('amount', amount);
		// data.append('synopsis', synopsis);
		// data.append('poster', imgLink);

		const data = {};
		data['title'] = title;
		data['cast'] = cast;
		data['genre'] = genre;
		data['type'] = type;
		data['amount'] = amount;
		data['synopsis'] = synopsis;
		data['poster'] = imgLink;
		console.log(data);

		await fetch(`${process.env.REACT_APP_SERVER}/admin/add-item`, {
			method: 'POST',
			headers: {
				'Content-Type': 'application/json',
			},
			body: JSON.stringify(data),
		});

		try {
			alert(`${type} added Successfully`);
			setTitle('');
			setGenre('');
			setCast('');
			setPoster('');
			setType('');
			setSynopsis('');
			setAmount('');

			setRedirect(true);
		} catch (error) {
			alert(error);
		}
	}

	useEffect(() => {
		if (poster) {
			const fileRead = new FileReader();
			fileRead.readAsDataURL(poster);
			fileRead.onload = (e) => {
				setImgLink(e.target.result);
			};
		}
	}, [poster]);

	if (redirect) {
		return <Navigate to="/admin" />;
	}
	// console.log(imgLink);
	function handleClick() {
		setPoster();
		setImgLink(null);
	}

	return (
		<Container>
			<form onSubmit={addMovie} encType="multipart/form-data">
				<div className="file-uploader">
					{imgLink ? (
						<>
							<FaTrashAlt color="#dc3545" size={30} onClick={handleClick} />
							<img src={imgLink} alt="movie poster" />
						</>
					) : (
						<>
							<label htmlFor="poster">
								<input
									type="file"
									accept="image/*"
									name="poster"
									id="poster"
									onChange={(e) => {
										setPoster(e.target.files[0]);
									}}
								/>
								<FaCloudUploadAlt size={80} />
								<span>Upload Movie Poster</span>
							</label>
						</>
					)}
				</div>
				<div className="l">
					<label htmlFor="title">Title</label>
					<input
						type="text"
						name="title"
						id=""
						placeholder="avengers:End game"
						value={title}
						onChange={(e) => setTitle(e.target.value)}
					/>
					<label htmlFor="title">Type</label>
					<select name="title" id="" onChange={(e) => setType(e.target.value)}>
						<option value="">Type (e.g Movie, Tv Series, Anime )</option>
						<option value="movie">Movie</option>
						<option value="series">Tv Series</option>
						<option value="anime">Anime</option>
					</select>
					<label htmlFor="genre">Genre</label>
					<input
						type="text"
						name="genre"
						id=""
						placeholder="Action, Thriller, Comedy ..."
						value={genre}
						onChange={(e) => setGenre(e.target.value)}
					/>
					<label htmlFor="cast">Cast</label>
					<input
						type="text"
						name="cast"
						id=""
						placeholder="Diane Russet, Chris Hemsworth ..."
						value={cast}
						onChange={(e) => setCast(e.target.value)}
					/>
					<label htmlFor="amount">Amount</label>
					<input
						type="text"
						name="amount"
						id=""
						placeholder=""
						value={amount}
						onChange={(e) => setAmount(e.target.value)}
					/>
					<label htmlFor="synopsis">Synopsis</label>
					<textarea
						name="synopsis"
						id=""
						cols="30"
						rows="6"
						value={synopsis}
						onChange={(e) => setSynopsis(e.target.value)}
					></textarea>

					<button>Add</button>
				</div>
			</form>
		</Container>
	);
}

const Container = styled.div`
	position: relative;
	top: 5%;
	width: 100%;
	height: fit-content;
	display: flex;
	background-color: #232020;
	form {
		position: relative;
		width: 80%;
		display: flex;
		background-color: #232020;
		div {
			width: 50%;
			display: flex;
			flex-direction: column;
			padding: 1rem;
			color: #ccc;
		}
		.file-uploader {
			position: relative;
			display: block;
			margin: 0 auto;
			text-align: center;
			border: 3px dashed #dc3545;
			width: 40%;
			img {
				height: 600px;
				display: block;
				margin: 3rem auto 0;
				max-width: 100%;
			}
			label {
				display: flex;
				height: 100%;
				flex-direction: column;
				justify-content: center;
				align-items: center;
				color: #dc3545;
				background-color: #2d2f31;
				cursor: pointer;
				input {
					display: none;
				}
			}
		}
		select {
			margin-bottom: 25px;
			background-color: #2d2f31;
			padding: 1rem;
			border: 3px solid #dc3545;
			border-radius: 5px;
			color: #ccc;
			font-size: 18px;

			option {
				font-size: 18px;
			}
		}
		input {
			height: 40px;
			margin-bottom: 25px;
			padding: 1.3rem;
			border-radius: 5px;
			border: 3px solid #dc3545;
			outline: none;
			background-color: #2d2f31;
			color: #ccc;
			font-size: 18px;

			&::placeholder {
				font-style: italic;
				font-size: 16px;
			}
		}

		button {
			height: 3rem;
			width: 30%;
			font-size: 18px;
			font-weight: bold;
			border-radius: 10px;
			border: none;
			background-color: #dc3545;
			color: #fff;
			display: block;
			margin-top: 2rem;
		}
		textarea {
			background-color: #2d2f31;
			border: 3px solid #dc3545;
			color: #ccc;
			padding: 10px;
			font-size: 17px;
			outline: none;
		}
	}
`;

export default AdminForm;
