import React from 'react';
import styled from 'styled-components';
import { Link } from 'react-router-dom';

const MovieCard = ({ movies }) => {
	return (
		<Container>
			{movies &&
				movies.map((movie) => {
					return (
						<div>
							<Link to={'/movie/' + movie._id}>
								<img
									src={movie.poster}
									alt="movie poster"
									style={{
										height: '350px',
										width: '300px',
									}}
								/>
								<div className="movie-list-overlay">
									<p className="title">{movie.title}</p>
									<ul className="stats">
										<li className="date">
											<p>2023</p>
										</li>
										<li className="rating">
											<p>4.4</p>
										</li>
									</ul>
									<button
									//  onClick={() => handleBook(item.title)}
									>
										Book Now{' '}
									</button>
								</div>
							</Link>
						</div>
					);
				})}
		</Container>
	);
};

const Container = styled.div`
	width: max-content;
	max-width: 80vw;
	padding-bottom: 2em;
	margin: 0 auto;
	display: flex;
	flex-direction: row;
	flex-wrap: wrap;
	gap: 2em;
	justify-content: start;
	/* */
	> div {
		width: 250px;
		background: #121212;
	}
	a {
		color: #fff;
	}
	.movie-list-overlay {
		display: flex;
		flex-direction: column;
		gap: 0.7em;
		padding-top: 0.5rem;

		.title {
			font-weight: bold;
			font-size: 1.1em;
			white-space: nowrap;
			height: fit-content;
			text-overflow: ellipsis;
			overflow: hidden;
			padding: 0 10px;
		}
		.stats {
			padding: 0 10px;
			p {
				font-size: 0.9em;
			}
		}
		button {
			background-color: #a62727;
			border: 0;
			outline: 0;
			color: #fff;
			width: 100%;
			padding: 0.5em;
			font-size: 1em;
		}
		ul {
			display: flex;
			flex-direction: row;
			justify-content: space-between;
		}
	}
`;

export default MovieCard;
