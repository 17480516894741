import React, { useState } from 'react';
import styled from 'styled-components';

export const Slider = ({ movies }) => {
	// {movies}
	// movies => movie => title, tags, image
	const [movieIdx] = useState(1);

	return (
		<Container>
			<div className="slider-details">
				<img src={movies[movieIdx].image} alt="movie poster" />
				<div className="slider-overlay">
					<p className="movie-title">{movies[movieIdx].title}</p>
					<ul className="tags">
						{movies[movieIdx].tags.map((tag, key) => (
							<li key={key}>
								<p>{tag}</p>
							</li>
						))}
					</ul>
					<ul className="slider-buttons">
						<li>
							<button className="book">Book</button>
						</li>
						<li>
							<button className="watch">+</button>
						</li>
					</ul>
				</div>
			</div>
		</Container>
	);
};

const Container = styled.div`
	width: 100%;
	background-color: #262222;
	.slider-details {
		position: relative;
		height: 25em;
		img {
			width: 100%;
			height: 100%;
			object-fit: cover;
			object-position: top;
			border-radius: 1em;
		}
		.slider-overlay {
			position: absolute;
			height: 100%;
			width: 100%;
			top: 0;
			left: 0;
			padding: 12em 0 0 2.3em;
			> .movie-title {
				font-size: 2em;
				color: #fff;
				text-transform: capitalize;
				font-weight: bold;
				margin-bottom: 0.5em;
			}
			> .tags {
				display: flex;
				flex-direction: row;
				gap: 1em;
				margin-bottom: 1em;
				li {
					border: 1px solid #ccc;
					p {
						color: #fff;
						text-transform: capitalize;
						padding: 0.5em;
					}
				}
			}
			> .slider-buttons {
				display: flex;
				flex-direction: row;
				gap: 1em;
				button {
					outline: 0;
					border: 0;
					color: #fff;
				}
				button.book {
					background-color: #a62727;
					text-transform: capitalize;
					font-size: 1em;
					padding: 0.5em;
				}
				button.watch {
					background-color: #9b909070;
					font-size: 2em;
					padding: 0 0.38em;
				}
			}
		}
	}
`;
