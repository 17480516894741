import React from 'react';
import styled from 'styled-components';

const InfoCard = ({ text, icon, movies, title }) => {
	let total_no;
	if (text === 'movie' || text === 'series' || text === 'anime') {
		total_no = movies.filter((movie) => {
			return movie.type === text;
		});
	} else {
		if (typeof text === 'object') {
			total_no = text;
		}
	}

	// console.log(total_no);

	return (
		<Container>
			<div>
				<h3>{title}</h3>
				<span>{icon}</span>
			</div>
			<h1>{total_no.length}</h1>
		</Container>
	);
};

const Container = styled.section`
	width: 250px;
	height: 180px;
	padding: 25px;
	background-color: #2d2f31;
	border: 1px solid #2d2f31;
	border-radius: 10px;
	display: flex;
	flex-direction: column;
	position: relative;
	top: 50%;
	box-shadow: 0px 10px 16px 0px rgba(0, 0, 0, 0.1);
	justify-content: center;
	color: #ccc;

	div {
		width: 100%;
		display: flex;
		justify-content: space-between;
	}
	h1 {
		font-size: 3rem;
	}
`;

export default InfoCard;
