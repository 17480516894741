import styled from 'styled-components';
import { NavLink, Link, Navigate } from 'react-router-dom';
import { useContext, useEffect, useState } from 'react';
import { UserContext } from '../hooks/UserContext';
import { AiFillHome } from 'react-icons/ai';
import { MdExplore } from 'react-icons/md';
import { RiMedalFill } from 'react-icons/ri';
import { MdMovieFilter } from 'react-icons/md';
import { MdDesktopMac } from 'react-icons/md';
import { ImVideoCamera } from 'react-icons/im';
import { FaUser } from 'react-icons/fa';
import { IoMdExit } from 'react-icons/io';

export const NavBar = () => {
	const { id, reset, isAdmin, setIsAdmin } = useContext(UserContext);
	const [redirect] = useState(false);
	useEffect(() => {
		fetch(process.env.REACT_APP_SERVER+'/profile', {
			credentials: 'include',
		}).then((response) => {
			response.json().then((userInfo) => {});
		});
	}, []);

	const logout = async () => {
		await fetch(process.env.REACT_APP_SERVER+'/logout', {
			credentials: 'include',
			method: 'POST',
		}).then((res) => {
			reset();
			setIsAdmin(false);
		});
	};
	if (redirect) {
		return <Navigate to={'/'} />;
	}
	return (
		<Container>
			<h1>Watch</h1>
			<div className="links">
				{!isAdmin ? (
					<>
						<ul className="menu">
							<li>
								<AiFillHome />
								<NavLink to="/">Home</NavLink>
							</li>
							<li>
								<MdExplore />
								<Link to={'/discover'}>Discover</Link>
							</li>
							<li>
								<RiMedalFill />
								<Link>Top Rated</Link>
							</li>
						</ul>
						<ul className="categories">
							<li>
								<MdMovieFilter />
								<Link to={'/movies'}>Movies</Link>
							</li>
							<li>
								<MdDesktopMac />
								<Link to="/series">TV Series</Link>
							</li>
							<li>
								<ImVideoCamera />
								<Link to="/animes">Anime</Link>
							</li>
						</ul>
						<ul className="general">
							{id?.length ? (
								<>
									<li>
										<FaUser />
										<Link>Profile</Link>
									</li>
									<li>
										<IoMdExit />
										<Link onClick={logout}>Log Out</Link>
									</li>
								</>
							) : (
								<>
									<li>
										<Link to="/login">Login</Link>
									</li>
									<li>
										<Link to="/signup">Sign Up</Link>
									</li>
								</>
							)}
						</ul>
					</>
				) : (
					<>
						<ul className="menu">
							<li>
								<Link to="/">Home</Link>
							</li>
							<li>
								<Link to="/add-item">Add Movie</Link>
							</li>
							<li>
								<Link to="/manage-bookings">Check bookings</Link>
							</li>
							<li>
								<IoMdExit />
								<Link onClick={logout}>Log Out</Link>
							</li>
						</ul>
					</>
				)}
			</div>
		</Container>
	);
};

const Container = styled.div`
	background: #1a1717;
	display: flex;
	flex-direction: column;
	width: 15vw;
	padding: 1em 0 1em 1em;
	height: 100vh;
	position: sticky;
	top: 0;
	color: white;
	h1 {
		margin: 0 0 2em 0;
	}
	.links {
		flex: 1;
		justify-content: center;
		display: flex;
		flex-direction: column;
	}
	ul {
		font-size: 1em;
		display: flex;
		flex-direction: column;
		padding-bottom: 1.8em;
		&::before {
			color: #c5c5c5;
			margin-bottom: 0.3em;
		}
		li {
			font-weight: bold;
			padding: 0.3em 0 0.3em 0.5em;
			display: flex;
			align-items: baseline;
			gap: 5px;
			a {
				color: inherit;
				display: inline-flex;
				flex-direction: row;
			}
			&:hover {
				color: #d21b1b;
				cursor: pointer;
			}
		}
	}
	.menu::before {
		content: 'menu';
		text-transform: capitalize;
		display: block;
	}
	.library::before {
		content: 'library';
		text-transform: capitalize;
		display: block;
	}
	.categories::before {
		content: 'categories';
		text-transform: capitalize;
		display: block;
	}
	.general::before {
		content: 'general';
		text-transform: capitalize;
		display: block;
	}
`;
