import { Routes, Route } from 'react-router-dom';
import { Home } from './pages/Home';
import { UserContext } from './hooks/UserContext';
import { useEffect, useState } from 'react';
import { BookingContext } from './hooks/BookingContext';
import AdminHome from './components/admin/AdminHome';
import { NavBar } from './components/NavBar';
import Addmovie from './components/admin/Addmovie';
import Discover from './pages/Discover';
import Login from './pages/Login';
import Signup from './pages/Signup';
import MoviePlace from './components/client/MoviePlace';
import './App.css';
import Movies from './pages/Movies';
import Animes from './pages/Animes';
import Series from './pages/Series';
import Bookings from './components/admin/Bookings';
import BookingSummary from './pages/BookingSummary';
import SeatsBooking from './components/client/SeatsBooking';

function App() {
	const [isAdmin, setIsAdmin] = useState(false);
	const [booked, setBooked] = useState({});
	const [movieBook, setMovieBook] = useState();
	const [isBooking, setIsBooking] = useState(false);

	// User
	const [userName, setUserName] = useState();
	const [email, setEmail] = useState();
	const [id, setId] = useState();
	const [name, setName] = useState();
	const reset = () => {
		setUserName();
		setEmail();
		setId();
		setName();
	};
	useEffect(() => {
		const body = document.querySelector('#root');
		isBooking
			? body.classList.add('hide-overlay')
			: body.classList.remove('hide-overlay');
	}, [isBooking]);
	return (
		<BookingContext.Provider
			value={{
				booked,
				setBooked,
				isBooking,
				setIsBooking,
				movieBook,
				setMovieBook,
			}}
		>
			<UserContext.Provider
				value={{
					username: 'Tomiwa',
					isAdmin,
					setIsAdmin,
					userName,
					setUserName,
					email,
					setEmail,
					id,
					setId,
					name,
					setName,
					reset,
				}}
			>
				<NavBar />
				<Routes>
					<Route exact path="/" element={<Home />} />
					<Route exact path="/login" element={<Login />} />
					<Route exact path="/signup" element={<Signup />} />
					<Route exact path="/discover" element={<Discover />} />
					<Route exact path="/admin" element={<AdminHome />} />
					<Route exact path="/add-item" element={<Addmovie />} />
					<Route exact path="/movie/:id" element={<MoviePlace />} />
					<Route exact path="/movies" element={<Movies />} />
					<Route exact path="/animes" element={<Animes />} />
					<Route exact path="/series" element={<Series />} />
					<Route exact path="/manage-bookings" element={<Bookings />} />
					<Route
						exact
						path="/booking-summary/:id"
						element={<BookingSummary />}
					/>
					<Route exact path="/select-seat/:id" element={<SeatsBooking />} />
				</Routes>
			</UserContext.Provider>
		</BookingContext.Provider>
	);
}

export default App;
