import React from 'react';
import styled from 'styled-components';
import AdminForm from './AdminForm';
function Addmovie() {
	return (
		<Container>
			<AdminForm />
		</Container>
	);
}

const Container = styled.div`
	position: relative;
	width: calc((100vw - 15vw) * 1);
`;

export default Addmovie;
