import { useRef } from 'react'
import styled from 'styled-components'

export const MovieNavSearch = () => {
  const search = useRef()
  const handleClick = (e) => {
    if(e.target.tagName !== "BUTTON")search.current.focus()
  }
  return (
    <Container onClick={handleClick}>
      <input type='text' placeholder='Quick Search' ref={search}/>
      <button>Search</button>
    </Container>
  )
}

const Container = styled.div`
  display: flex;
  flex-direction: row;
  color: white;
  border: 1px solid #646464;
  align-items: center;
  padding-left: 1em;
  border-radius: 0.4em;
  &:focus-within{
    border-color: #ccc;
  }
  input{
    flex: 1;
    background-color: transparent;
    color: #fff;
    border: 0;
    outline: 0;
    line-height: 2em;
    font-size: 1em;
  }
  button{
    background-color: transparent;
    border: 0;
    outline: 0;
    color: #fff;
    padding: 1em;
  }
`