import React, { useContext, useEffect, useState } from 'react';
import styled from 'styled-components';
import InfoCard from './InfoCard';
import { RiMovieFill } from 'react-icons/ri';
import { IoIosEasel } from 'react-icons/io';
import { AiFillVideoCamera } from 'react-icons/ai';
// import { IoIosCreate } from 'react-icons/io';
import { FaTrashAlt } from 'react-icons/fa';
import { BsCalendar2CheckFill } from 'react-icons/bs';
import { FaBell } from 'react-icons/fa';
import { UserContext } from '../../hooks/UserContext';
import { Navigate } from 'react-router-dom';

function AdminHome() {
	const [allItems, setAllItems] = useState(null);
	const { id } = useContext(UserContext);
	// console.log(id);

	// This fetches all the data from a database
	useEffect(() => {
		const fetchAllData = async () => {
			const response = await fetch(`${process.env.REACT_APP_SERVER}/admin/all`);
			const json = await response.json();
			// console.log(json);
			if (response.ok) {
				setAllItems(json);
			}
		};

		fetchAllData();
	}, []);

	// Function to delete item from the list
	const handleDelete = async (id) => {
		const response = await fetch(
			`${process.env.REACT_APP_SERVER}/admin/del-movie/${id}`,
			{
				method: 'DELETE',
			}
		);
		const delItem = await response.json();
		console.log(delItem);
		if (response.ok) {
			alert('item deleted successfully');
			window.location.reload();
		}
	};

	// const handleUpdate = async (id) => {
	// 	const response = await fetch(
	// 		`${process.env.REACT_APP_SERVER}/admin/update/${id}`,
	// 		{
	// 			method: 'PATCH',
	// 		}
	// 	);

	// 	const update = response.json();
	// 	console.log(update);
	// };

	if (!id?.length || allItems === null) return <Navigate to="/" />;
	return (
		id && (
			<Container>
				<AdminNav>
					<div>
						<h1>Admin Dashboard</h1>
					</div>

					<NotifProfile>
						<div
							className="notification"
							style={{
								position: 'relative',
							}}
						>
							<FaBell
								style={{
									fontSize: '25px',
									color: '#0EA5E9',
								}}
							/>

							<span
								style={{
									height: '20px',
									width: '20px',
									display: 'flex',
									justifyContent: 'center',
									alignItems: 'center',
									fontWeight: 'bold',
									position: 'absolute',
									top: '8px',
									left: '30px',
									backgroundColor: '#DC3545',
									color: '#fff',
									padding: '2px',
									borderRadius: '50px',
								}}
							>
								0
							</span>
						</div>
					</NotifProfile>
				</AdminNav>
				<Dashboard>
					<InfoCard
						title="Movies"
						movies={allItems}
						text="movie"
						icon={
							<RiMovieFill
								style={{
									fontSize: '30px',
									color: '#3b82f6',
								}}
							/>
						}
					/>
					<InfoCard
						title="Tv Series"
						movies={allItems}
						text="series"
						icon={
							<IoIosEasel
								style={{
									fontSize: '30px',
									color: '#ef4444',
								}}
							/>
						}
					/>
					<InfoCard
						title="Animes"
						movies={allItems}
						text="anime"
						icon={
							<AiFillVideoCamera
								style={{
									fontSize: '30px',
									color: '#fbbf24',
								}}
							/>
						}
					/>
					<InfoCard
						title="Total"
						movies={allItems}
						text={allItems}
						icon={
							<BsCalendar2CheckFill
								style={{
									fontSize: '30px',
									color: '#22c55e',
								}}
							/>
						}
					/>
				</Dashboard>
				<DataTable>
					<div>
						<h1>List</h1>
					</div>
					<table>
						<thead>
							<tr>
								<th>Poster</th>
								<th>Title</th>
								<th>Type</th>
								<th>Delete</th>
							</tr>
						</thead>
						<tbody>
							{allItems?.length ? (
								allItems.map((item, index) => {
									return (
										<tr>
											<td>
												<img src={item.poster} alt="movie poster" />
											</td>
											<td>{item.title}</td>
											<td>{item.type}</td>
											{/* <td>
												<IoIosCreate
													className="cursor-pointer text-[#22c55e]"
													size={20}
													// onClick={handleUpdate}
												/>
											</td> */}
											<td>
												<FaTrashAlt
													className="cursor-pointer text-[#dc3545]"
													size={20}
													onClick={(e) => {
														handleDelete(item._id);
													}}
												/>
											</td>
										</tr>
									);
								})
							) : (
								<h2 className="text-white text-center p-5">
									No movies added yet
								</h2>
							)}
						</tbody>
					</table>
				</DataTable>
			</Container>
		)
	);
}

const Container = styled.div`
	display: flex;
	flex-direction: column;
	align-items: center;
	/* gap: 1rem; */
	width: calc((100vw - 15vw) * 1);
	position: relative;
	/* border-bottom: 1px solid #eee; */
	/* background-color: tomato; */
	height: fit-content;
`;

const AdminNav = styled.nav`
	width: 90%;
	display: flex;
	justify-content: space-between;
	align-items: center;
	color: #ccc;
	/* background-color:skyblue; */
	margin: 0 auto;
	padding: 10px;
`;

const NotifProfile = styled.div`
	/* background-color:cyan; */
	width: 200px;
	display: flex;
	justify-content: space-around;
	align-items: center;

	div {
		background-color: #ddd;
		height: 55px;
		width: 55px;
		border-radius: 50%;
		display: flex;
		justify-content: center;
		align-items: center;
	}
`;

const Dashboard = styled.section`
	width: 100%;
	height: 35vh;
	display: flex;
	justify-content: space-around;
	background-color: #1a1717;
	position: relative;
`;
const DataTable = styled.section`
	width: 95%;
	/* background-color: pink; */
	display: flex;
	flex-direction: column;

	div {
		margin-top: 8rem;
		background-color: #1a1717;
		/* border: 1px solid #ddd; */
		color: #ccc;
		border-top-right-radius: 10px;
		border-top-left-radius: 10px;
		padding: 1rem;
		box-shadow: 0px 10px 16px 0px rgba(0, 0, 0, 0.1);
	}

	table {
		box-shadow: 0px 10px 16px 0px rgba(0, 0, 0, 0.1);
		width: 100%;
		text-align: justify;
		/* margin: 10rem 2rem; */
		border-collapse: collapse;
	}
	table,
	tr {
		border: 2px solid #2d2f31;
	}
	td {
		width: fit-content;
		padding: 10px;
		color: #ccc;
	}
	th {
		background-color: #2d2f31;
		color: #ccc;
		padding: 15px;
	}
	img {
		height: 50px;
		display: block;
		margin: 0 20px;
	}
`;
export default AdminHome;
