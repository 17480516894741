import React, { useEffect, useState } from 'react';
import styled from 'styled-components';
import { useParams, useNavigate } from 'react-router-dom';

const MoviePlace = () => {
	const { id } = useParams();
	const [movie, setMovie] = useState(null);
	const [date, setDate] = useState('');
	const [noPeople, setNoPeople] = useState(1);
	const [data, setData] = useState();
	const navigate = useNavigate();

	useEffect(() => {
		if (!id) {
			return;
		}
		fetch(process.env.REACT_APP_SERVER + `/admin/movie/${id}`)
			.then((response) => response.json())
			.then((data) => {
				setMovie(data);
			});
	}, [id]);

	useEffect(() => {
		if (data) {
			navigate(`/select-seat/${id}`, { state: data });
		}
	}, [data, id, navigate]);

	if (!movie) return '';

	const handleBooking = () => {
		const booking_data = {
			movieID: movie._id,
			title: movie.title,
			date: date,
			poster: movie.poster,
			noPersons: noPeople,
			ticket_price: noPeople * movie.amount,
			total_price: noPeople * movie.amount,
		};
		setData(booking_data);
		console.log(data);
	};

	return (
		<Container>
			<div className="image-box">
				<img
					src={movie.poster}
					alt="poster"
					style={{
						height: '100%',
						width: '100%',
						display: 'block',
						margin: '0 auto',
						borderRadius: '25px',
						boxShadow: '0 10px 30px 10px #141414',
						objectFit: 'cover',
					}}
				/>
			</div>
			<div className="details">
				<div className="movie-info">
					<h2>{movie.title}</h2>
					<span>Cast</span>
					<ul className="cast">
						{movie.cast.map((cast) => (
							<li key={cast}>{cast}</li>
						))}
					</ul>
					<ul className="genre">
						{movie.genre.map((genre) => (
							<li key={genre}>{genre}</li>
						))}
					</ul>
					<p className="synopsis">{movie.synopsis}</p>
				</div>
				<div className="booking">
					<div className="date">
						<h3>Date</h3>
						<input
							type="date"
							value={date}
							onChange={(e) => {
								console.log(e.target.value);
								setDate(e.target.value);
							}}
						/>
					</div>
					<div className="pricing">
						<div className="price">
							<h3>Price</h3>
							<p>
								{`₦${movie.amount.toLocaleString()}`} <span>/ person</span>
							</p>
						</div>
						<div className="num-people">
							<h3>No of Persons</h3>
							<input
								type="number"
								value={noPeople}
								min={'1'}
								onChange={(e) => {
									setNoPeople(e.target.value);
								}}
							/>
						</div>
						<div className="total-wrapper">
							<h3>Total</h3>
							<span>₦{`${(noPeople * movie.amount).toLocaleString()}`}</span>
						</div>

						<button
							onClick={handleBooking}
							// onClick={() => {
							// 	setOpenSeat(!openSeat);
							// }}
						>
							Book
						</button>
					</div>
				</div>
			</div>
			{/* <SeatsBooking
				open={openSeat}
				// onClose={() => setOpenSeat(false)}
			/> */}
		</Container>
	);
};

const Container = styled.div`
	width: 100%;
	height: 100vh;
	padding: 2rem 1rem 1rem 3rem;
	background-color: #262626;
	overflow: none;
	display: flex;
	gap: 1rem;
	justify-content: space-between;
	color: #e4e4e7;
	/* position: relative; */

	.image-box {
		position: relative;
		width: 40%;
		height: 100%;
	}

	.details {
		/* background-color: pink; */
		width: 60%;
		display: flex;
		flex-direction: column;
		justify-content: space-between;
		position: relative;

		.movie-info {
			display: flex;
			flex-direction: column;
			gap: 1rem;
			/* border: 1px solid #eee; */
			border-radius: 20px;
			padding: 2rem;
			background: #1a1717;
			box-shadow: 0 0px 10px 1px rgba(0, 0, 0, 0.3);

			h2 {
				font-size: 2rem;
			}
			span {
				font-size: 17px;
				font-weight: 700;
				color: #a1a1aa;
			}
			.cast {
				display: flex;
				gap: 1rem;

				li {
					font-size: 13px;
				}
			}

			.genre {
				display: flex;
				gap: 1rem;

				li {
					font-size: 14px;
					font-weight: 700;
					background-color: #a62727;
					border-radius: 5px;
					padding: 10px;
				}
			}
			.synopsis {
				font-size: 0.7rem;
			}
		}

		.booking {
			background: #1a1717;
			height: 50%;
			position: relative;
			border-radius: 20px;
			padding: 2rem 1rem 1rem 3rem;
			display: flex;
			justify-content: space-around;

			.pricing {
				width: 50%;
				display: flex;
				flex-direction: column;
				justify-content: center;
				gap: 1rem;

				.price {
					display: flex;
					flex-direction: column;
					gap: 0.5rem;
				}

				.num-people {
					display: flex;
					flex-direction: column;
					gap: 0.5rem;
					input[type='number'] {
						height: 35px;
						width: 200px;
						background: #262626;
						border-radius: 5px;
						border: 1px solid #e4e4e7;
						color: #e4e4e7;
						padding: 0.6rem;
					}
				}
				.total-wrapper {
					display: flex;
					align-items: center;
					justify-content: space-between;

					span {
						font-size: 22px;
					}
				}
				button {
					height: 50px;
					font-size: 20px;
					font-weight: 600;
					background-color: #a62727;
					color: #fff;
					border: none;
					border-radius: 5px;
				}
			}
			.date {
				/* background-color:greenyellow; */
				width: 40%;
				display: flex;
				flex-direction: column;
				gap: 0.5rem;

				input[type='date'] {
					height: 35px;
					width: 220px;
					background: #262626;
					border-radius: 5px;
					border: 1px solid #e4e4e7;
					color: #e4e4e7;
					padding: 0.6rem;
				}
			}
		}
	}
`;

export default MoviePlace;
